export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49')
];

export const server_loads = [];

export const dictionary = {
		"/": [5],
		"/about-us": [6],
		"/appointments": [7],
		"/calendar": [8],
		"/demo": [9,[2]],
		"/education": [10],
		"/error_appointments": [12],
		"/error": [11],
		"/health-records": [13,[3]],
		"/health-records/access-log": [14,[3]],
		"/health-records/allergies": [15,[3]],
		"/health-records/assessments-treatment-plans": [16,[3]],
		"/health-records/care-team": [17,[3]],
		"/health-records/clinical-notes": [18,[3]],
		"/health-records/clinical-notes/[id]": [19,[3]],
		"/health-records/diagnoses": [20,[3]],
		"/health-records/goals": [21,[3]],
		"/health-records/health-concerns": [22,[3]],
		"/health-records/health-documents": [23,[3]],
		"/health-records/immunizations": [24,[3]],
		"/health-records/medications": [25,[3]],
		"/health-records/patient-info": [26,[3]],
		"/health-records/procedures": [27,[3]],
		"/health-records/share": [28,[3]],
		"/health-records/test-results": [29,[3]],
		"/health-records/test-results/[id]": [30,[3]],
		"/health-records/test-results/[id]/detail/[index]": [31,[3]],
		"/health-records/test-results/[id]/history/[index]": [32,[3]],
		"/health-records/vds": [33,[3]],
		"/health-records/vitals": [34,[3]],
		"/home": [35],
		"/ineligible": [36],
		"/library": [37,[4]],
		"/library/cancerdrugs": [38,[4]],
		"/library/cancerdrugs/[key]": [39,[4]],
		"/library/types": [40,[4]],
		"/library/types/[id]": [41,[4]],
		"/library/types/[id]/[key]": [42,[4]],
		"/messages": [43],
		"/messages/compose": [44],
		"/messages/inbox": [45],
		"/messages/sent": [46],
		"/messages/thankyou": [47],
		"/messages/thread/[id]": [48],
		"/nopatientid": [49]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';